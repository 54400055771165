function setContentWidth() {

    if (jQuery(window).width() > 1200) {

        jQuery('section.content .main-content').width(jQuery('section.content .container').width() - '300');

    } else {

        jQuery('section.content .main-content').css('width', '100%');

    }

}



jQuery(document).ready(function($) {

    /*
    |------------------------------------------------------
    | MOBILE NAVIGATION
    |------------------------------------------------------
    */

    $("nav.mobile-navigation").navigation({
        maxWidth: "99999px",
        label: false,
        type: 'push',
        gravity: 'right'
    });


    /*
    |------------------------------------------------------
    | CONTENT / SIDEBAR WIDTHS
    |------------------------------------------------------
    */

    setContentWidth();


    /*
    |------------------------------------------------------
    | SWIPER
    |------------------------------------------------------
    */

    var legendSwiper = new Swiper('.legend-mobile .swiper-container', {
        loop: false,
        pagination: null
    });

    var articleSwiper = new Swiper('.articles-mobile .swiper-container', {
        loop: false,
        pagination: null
    });

    var featuredCoursesSwiper = new Swiper('.widget .featured-courses .swiper-container', {
        loop: true,
        pagination: null,
        nextButton: $('.widget .featured-courses .footer .fa-arrow-circle-right'),
        prevButton: $('.widget .featured-courses .footer .fa-arrow-circle-left')
    });

    var recentJobsSwiper = new Swiper('.recent-jobs .swiper-container', {
        loop: false,
        pagination: null,
        nextButton: $('.vacancy-pagination .fa-arrow-circle-right'),
        prevButton: $('.vacancy-pagination .fa-arrow-circle-left')
    });

    var jobsByCategorySwiper = new Swiper('.jobs-by-category .swiper-container', {
        loop: false,
        pagination: null,
        nextButton: $('.vacancy-pagination .fa-arrow-circle-right'),
        prevButton: $('.vacancy-pagination .fa-arrow-circle-left'),
    });


    /*
    |------------------------------------------------------
    | SELECT2
    |------------------------------------------------------
    */

    $('.filters .job-sectors, .filters  .job-locations, .filters .companies').select2();


    /*
    |------------------------------------------------------
    | MATCH HEIGHTS
    |------------------------------------------------------
    */

    $('section.articles .box .content').matchHeight();
    $('section.articles .box img').matchHeight();

    $('section.articles-mobile .box .content').matchHeight();
    $('section.articles-mobile .box img').matchHeight();

    $('section.content .blog-posts .item').matchHeight();
    $('.widget .featured-courses .content').matchHeight();


    /*
    |------------------------------------------------------
    | SHARE
    |------------------------------------------------------
    */

    $('.send-to-a-friend-button').click(function() {

        if ($(this).hasClass('active')) {

            $('.send-to-a-friend-button').removeClass('active');

        } else {

            $('.send-to-a-friend-button').removeClass('active');
            $(this).toggleClass('active');

        }

    });


    /*
    |------------------------------------------------------
    | EVENTS
    |------------------------------------------------------
    */

    $('section.header-bar .search').on('click', function() {
        $(this).toggleClass('active');

        if ($('section.header-bar .search').hasClass('active')) {
            $('section.header-bar .search-container').show();
        } else {
            $('section.header-bar .search-container').hide();
        }

    });


    $('.owl-carousel').owlCarousel({
        items: 4
    });

});



jQuery(window).resize(function($) {

    setContentWidth();

});
